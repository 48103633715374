<template>
  <no-subcription v-if="!getSubscription"/>
  <div v-else  class="page-add-inventory">
    <app-loading v-show="loadingData" />
    <v-container v-if="!loadingData">
      <v-card>
        <v-card-title>
          <p>{{
            $vuetify.lang.t("$vuetify.menu.sell_mark")
          }}</p>
        </v-card-title>
        <v-card-text>
            <v-tabs
                v-model="tab"
                background-color="transparent"
                color="basil"
                grow
            >
                <v-tab
                    v-for="item in items"
                    :key="item.text"
                >
                    <v-icon>{{ item.icon }}</v-icon>
                    {{ item.text }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-card
                        color="basil"
                        flat
                    >
                        <v-card-text>
                          <resume-filter-component @updateFilter="loadData"/>
                            <v-expansion-panels
                                v-model="panel"
                                style="margin: 0"
                                multiple
                            >
                                <v-expansion-panel style="margin: 0">
                                    <v-expansion-panel-header>
                                        <div>
                                            <v-icon>mdi-chart-line</v-icon>
                                            <span
                                                style="text-transform: uppercase;font-weight: bold"
                                            >
                    {{
                                                    $vuetify.lang.t(
                                                        "$vuetify.report.graphics"
                                                    )
                                                }}
                  </span>
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row>
                                            <v-col class="py-0"
                                                cols="12"
                                                :md="localSalesByMark.length > 0 ? 5 : 12">
                                                <v-card
                                                    class="mx-auto"
                                                    tile
                                                >
                                                    <v-list dense>
                                                        <v-subheader>
                                                            {{
                                                                localSalesByMark.length >
                                                                0
                                                                    ? $vuetify.lang.t(
                                                                    "$vuetify.report.top5Payment"
                                                                    )
                                                                    : $vuetify.lang.t(
                                                                    "$vuetify.report.noTop5"
                                                                    )
                                                            }}
                                                        </v-subheader>
                                                        <v-list-item-group
                                                            color="primary"
                                                        >
                                                            <v-list-item v-for="(item, i) in localSalesByMark.slice(0,5)" :key="i">
                                                                <v-list-item-icon>
                                                                    <v-chip :color="item.color"
                                                                        dark
                                                                    >
                                                                        {{ item.color }}
                                                                    </v-chip>
                                                                </v-list-item-icon>
                                                                <v-list-item-content>
                                                                    <v-list-item-title
                                                                        v-text="`${item.name !== '' ? item.name : $vuetify.lang.t('$vuetify.no_defined')}`"
                                                                    />
                                                                    <v-list-item-subtitle>
                                                                      <b>{{ $vuetify.lang.t('$vuetify.dashboard.revenue') }}</b>{{ ': ' + parseFloat(item.margin).toFixed(2) + ' ' + getCurrency}}
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-list-item-group>
                                                    </v-list>
                                                </v-card>
                                            </v-col>
                                            <v-col
                                                v-if="localSalesByMark.length > 0"
                                                class="py-0"
                                                cols="12"
                                                md="7"
                                            >
                                                <highcharts :options="chartOptions" />
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel style="margin: 0">
                                    <v-expansion-panel-header>
                                        <div>
                                            <v-icon>mdi-table-large</v-icon>
                                            <span
                                                style="text-transform: uppercase;font-weight: bold"
                                            >
                    {{
                                                    $vuetify.lang.t(
                                                        "$vuetify.panel.basic"
                                                    )
                                                }}
                  </span>
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                      <product-table-info :local-sales-by-product="localSalesByMark" :currency="getCurrency" :um="false" :expand-table="false"/>
                                        <!--app-data-table
                                            :title="$vuetify.lang.t('$vuetify.titles.list',[$vuetify.lang.t('$vuetify.menu.vending')])"
                                            :headers="getTableColumns"
                                            csv-filename="ProductBuys"
                                            :items="localSalesByMark"
                                            :options="vBindOption"
                                            :sort-by="['no_facture']"
                                            :sort-desc="[false, true]"
                                            multi-sort
                                            :view-show-filter="false"
                                            :view-edit-button="false"
                                            :view-new-button="false"
                                            :view-delete-button="false"
                                            :is-loading="isTableLoading"
                                        >
                                            <template v-slot:item.name="{ item }">
                                                {{ item.name ? item.name : $vuetify.lang.t('$vuetify.no_defined')}}
                                            </template>
                                            <template v-slot:item.cantTransactions="{ item }">
                                                {{ parseFloat(item.cantTransactions).toFixed(2) }}
                                            </template>
                                            <template v-slot:item.grossPrice="{ item }">
                                                {{ parseFloat(item.grossPrice).toFixed(2) + ' ' + getCurrency}}
                                            </template>
                                        </app-data-table-->
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card
                        color="basil"
                        flat
                    >
                        <v-card-text v-if="parseFloat(getSalesProductData.totalPrice).toFixed(2) !== '0.00' && firstDate && secondDate">

                          <sale-summary :products-data="getSalesProductData" :currency="getCurrency" :first-date="firstDate" :second-date="secondDate"/>
                          <mark-summary :marks="localSalesByMark"/>
                        </v-card-text>
                        <v-card-text v-else>
                            <p v-if="firstDate && secondDate">En el intervalo desde
                                {{ firstDate.toLocaleDateString() + ' ' + firstDate.getHours() + ':' + firstDate.getMinutes() }}
                                hasta
                                {{ secondDate.toLocaleDateString() + ' ' + secondDate.getHours() + ':' + secondDate.getMinutes() }},
                                no se han efectuado ventas</p>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import AppLoading from '../../../components/core/AppLoading'
import { Chart } from 'highcharts-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import ResumeFilterComponent from './ResumeFilterComponent'
import SaleSummary from './summary/SaleSummary'
import MarkSummary from './summary/MarkSummary'
import ProductTableInfo from './ProductTableInfo'

export default {
  name: 'SalesMark',
  components: { ProductTableInfo, MarkSummary, SaleSummary, ResumeFilterComponent, AppLoading, highcharts: Chart },
  data () {
    return {
      firstDate: null,
      secondDate: null,
      totalSale: 0.00,
      tab: null,
      items: [{
        text: this.$vuetify.lang.t('$vuetify.report.graphics'),
        icon: 'mdi-chart-bar'
      }, {
        text: this.$vuetify.lang.t('$vuetify.menu.resume'),
        icon: 'mdi-file-document'
      }],
      min: 0,
      max: 5,
      sliderModel: 5,
      loadingData: false,
      panel: [0],
      seriesData: [],
      chartOptions: {},
      vBindOption: {
        itemKey: 'no_facture',
        singleExpand: false,
        showExpand: false
      },
      menu: false,
      formRule: this.$rules,
      localSalesByMark: []
    }
  },
  computed: {
    ...mapState('sale', ['salesByMark', 'isTableLoading']),
    ...mapGetters('sale', ['getSalesProductData']),
    ...mapGetters('auth', ['user']),
    ...mapState('auth', ['company_subscription']),
    getSubscription () {
      return this.company_subscription ? !this.company_subscription.is_caduced : false
    },
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    },
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.report.cant_sale'),
          value: 'cantTransactions',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.variants.total_price'),
          value: 'grossPrice',
          select_filter: true
        }
      ]
    }
  },
  methods: {
    ...mapActions('sale', ['getSalesByMark']),
    async loadData (firstDate, secondDate, localShop) {
      this.firstDate = firstDate
      this.secondDate = secondDate
      await this.getSalesByMark({ shops: localShop, dates: [firstDate, secondDate], online: this.$route.path.split('online').length > 1 })
        .then(() => {
          this.localSalesByMark =
                    this.salesByMark.sort(function (a, b) {
                      if (a.margin > b.margin) return -1
                      if (a.margin < b.margin) return 1
                      return 0
                    })
        })
      const categories = []
      const series = {
        grossPrice: [],
        totalDiscount: [],
        netPrice: [],
        totalCost: [],
        totalTax: []
      }
      this.localSalesByMark.slice(0, 4).forEach(v => {
        // categories.push(v.name !== '' ? v.name : this.$vuetify.lang.t('$vuetify.no_defined'))
        const netP = parseFloat(v.grossPrice - v.totalDiscount - v.totalCost)
        categories.push(v.name)
        series.totalCost.push(v.totalCost)
        series.grossPrice.push(v.grossPrice)
        series.totalDiscount.push(v.totalDiscount)
        series.totalTax.push(v.totalTax)
        series.netPrice.push(netP)
      })
      this.chartOptions = {
        chart: {
          type: 'column'
        },
        title: {
          text: this.$vuetify.lang.t('$vuetify.report.barGraphics')
        },
        subtitle: {
          text: 'inneli.com'
        },
        xAxis: {
          categories: categories,
          crosshair: true
        },
        yAxis: {
          min: 0,
          title: {
            text: this.getCurrency
          }
        },
        tooltip: {
          headerFormat:
                        '<span style="font-size:10px"><b>{point.key}</b></span><table>',
          pointFormat:
                        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:.1f} ' +
                          this.getCurrency +
                        '</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: [
          {
            name: this.$vuetify.lang.t(
              '$vuetify.variants.total_cost'
            ),
            data: series.totalCost
          },
          {
            name: this.$vuetify.lang.t('$vuetify.report.grossSale'),
            data: series.grossPrice
          },
          {
            name: this.$vuetify.lang.t('$vuetify.report.discountsSale'
            ),
            data: series.totalDiscount
          },
          {
            name: this.$vuetify.lang.t(
              '$vuetify.tax.total_pay_tax'
            ),
            data: series.totalTax
          },
          {
            name: this.$vuetify.lang.t('$vuetify.report.netPrice'),
            data: series.netPrice
          }
        ],
        credits: {
          enabled: false
        }
      }
    },
    rowClick ($event) {}
  }
}
</script>

<style scoped></style>
