<template>
  <v-row>
    <v-col
      class="py-0"
      cols="12"
      :md="horizontal ? 4 : 12"
    >
      <app-datetime-picker
        v-model="firstDate"
        :max-date="secondDate"
        :clearText="$vuetify.lang.t('$vuetify.actions.cancel')"
        :okText="$vuetify.lang.t('$vuetify.actions.accept')"
        :label="$vuetify.lang.t('$vuetify.assistance.entry')"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      :md="horizontal ? 4 : 12"
    >
      <app-datetime-picker
        v-model="secondDate"
        :min-date="firstDate"
        :clearText="$vuetify.lang.t('$vuetify.actions.cancel')"
        :okText="$vuetify.lang.t('$vuetify.actions.accept')"
        :label="$vuetify.lang.t('$vuetify.assistance.entry')"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      :md="horizontal ? 4 : 12"
    >
      <v-select
        v-model="localShops"
        chips
        rounded
        solo
        :items="shops"
        :label="$vuetify.lang.t('$vuetify.menu.shop')"
        item-text="name"
        item-value="id"
        :loading="isShopLoading"
        :disabled="!!isShopLoading"
        required
        multiple
        :rules="formRule.country"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ResumeFilterComponent',
  props: {
    horizontal: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      firstDate: new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000),
      secondDate: new Date(),
      localShops: [],
      formRule: this.$rules
    }
  },
  computed: {
    ...mapState('shop', ['shops', 'isShopLoading'])
  },
  watch: {
    localShops: function () {
      this.loadData()
    },
    firstDate: function () {
      this.loadData()
    },
    secondDate: function () {
      this.loadData()
    }
  },
  async created () {
    await this.getShops().then(() => {
      this.shops.map((shop) => {
        this.localShops.push(shop.id)
      })
    })
    this.loadData()
  },
  methods: {
    ...mapActions('shop', ['getShops']),
    loadData () {
      this.$emit('updateFilter', this.firstDate, this.secondDate, this.localShops)
    }
  }
}
</script>

<style scoped>

</style>
