<template>
  <v-container>
    <p v-if="productsData.totalPrice > 0">En el intervalo desde
      {{ firstDate.toLocaleDateString() + ' ' + firstDate.getHours() + ':' + firstDate.getMinutes() }}
      hasta
      {{ secondDate.toLocaleDateString() + ' ' + secondDate.getHours() + ':' + secondDate.getMinutes() }},
      usted ha tenido una <b>Venta Neta de: {{ parseFloat(productsData.totalPrice).toFixed(2) }} {{ currency }}</b>,
      pagando de Impuestos General:
      <b>{{ parseFloat(productsData.totalTax).toFixed(2) }} {{ currency }}</b> y de Impuestos a productos:  <b>{{ parseFloat(productsData.subTaxesProducts).toFixed(2) }} {{ currency }}</b></p>
    <p v-if="productsData.totalPrice > 0">Tuvo <b>Venta Bruta de: {{ parseFloat(productsData.grossPrice - productsData.subTaxesProducts).toFixed(2) }} {{ currency }}</b>, estas ventas tuvieron de <b>Costo(Inversión en productos):
      {{ parseFloat(productsData.totalCost).toFixed(2) }}
      {{ currency }}</b>, usted dejó de ganar por concepto de <b>Descuento
      {{ parseFloat(productsData.totalDisc).toFixed(2) }}
      {{ currency }}</b>,
      y ha devuelto por <b>Reembolso:
        {{ parseFloat(productsData.totalDisc).toFixed(2) }}
        {{ currency }}</b>,
      obteniendo como <b> {{ productsData.total > 0 ? 'ganancia' : 'pérdida' }}
        {{ parseFloat(productsData.total).toFixed(2) }}
        {{ currency }}</b>.</p>
  </v-container>
</template>

<script>
export default {
  name: 'SaleSummary',
  props: {
    productsData: {
      type: Object,
      default: function () {
        return {}
      }
    },
    firstDate: {
      type: Date,
      default: function () {
        return new Date()
      }
    },
    secondDate: {
      type: Date,
      default: function () {
        return new Date()
      }
    },
    currency: {
      type: String,
      default: function () {
        return ''
      }
    }
  }
}
</script>

<style scoped>

</style>
