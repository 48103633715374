<template>

  <app-data-table
    :title="$vuetify.lang.t('$vuetify.titles.list',[$vuetify.lang.t('$vuetify.menu.vending')])"
    :headers="getTableColumns"
    csv-filename="ProductBuys"
    :items="localSalesByProduct"
    :options="vBindOption"
    :sort-by="['no_facture']"
    :sort-desc="[false, true]"
    multi-sort
    show
    :view-tour-button="false"
    :view-show-filter="false"
    :view-edit-button="false"
    :view-new-button="false"
    :view-delete-button="false"
    :is-loading="isTableLoading"
  >
    <template v-slot:item.cantTransactions="{ item }">
      {{ parseFloat(item.cantTransactions).toFixed(2) }} {{um ? item.um.name : ''}}
    </template>
    <template v-slot:item.totalCost="{ item }">
      {{ parseFloat(item.totalCost).toFixed(2) + ' ' + currency }}
    </template>
    <template v-slot:item.grossPrice="{ item }">
      {{ parseFloat(item.grossPrice).toFixed(2) + ' ' + currency }}
    </template>
    <template v-slot:item.totalDiscount="{ item }">
      {{ parseFloat(item.totalDiscount).toFixed(2) + ' ' + currency }}
    </template>
    <template v-slot:item.totalTax="{ item }">
      {{ parseFloat(item.totalTax).toFixed(2) + ' ' + currency }}
    </template>
    <template v-slot:item.netPrice="{ item }">
      {{ parseFloat(item.netPrice).toFixed(2) + ' ' + currency }}
    </template>
    <template v-slot:item.netPrice="{ item }">
      {{ parseFloat(item.netPrice).toFixed(2) + ' ' + currency }}
    </template>
    <template v-slot:item.margin="{ item }">
      {{ parseFloat(item.margin).toFixed(2) + ' ' + currency }}
    </template>
    <template
      v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }"
    >
      <v-btn
        v-if="item.variant_cost.length > 0"
        color="primary"
        fab
        x-small
        dark
        @click="expand(!isExpanded)"
      >
        <v-icon v-if="isExpanded">
          mdi-chevron-up
        </v-icon>
        <v-icon v-else>
          mdi-chevron-down
        </v-icon>
      </v-btn>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td
        :colspan="headers.length"
        style="padding: 0 0 0 0"
      >
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                {{ $vuetify.lang.t("$vuetify.articles.ref") }}
              </th>
              <th class="text-left">
                {{ $vuetify.lang.t("$vuetify.variants.cant") }}
              </th>
              <th class="text-left" v-if="isManagerIn">
                {{ $vuetify.lang.t("$vuetify.articles.cost") }}
              </th>
              <th class="text-left">
                {{ $vuetify.lang.t("$vuetify.articles.price") }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="variantCost in item.variant_cost"
              :key="variantCost.id"
            >
              <td>
                {{variantCost.ref}}
              </td>
              <td >
                {{parseFloat(variantCost.cant).toFixed(2) + ' ' + item.um.name}}
              </td>
              <td v-if="isManagerIn">
                {{parseFloat(variantCost.cost).toFixed(2) + ' ' + currency}}
              </td>
              <td>
                {{parseFloat(variantCost.price).toFixed(2) + ' ' + currency}}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </td>
    </template>
  </app-data-table>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ProductTableInfo',
  props: {
    localSalesByProduct: {
      type: Array,
      default: function () {
        return []
      }
    },
    currency: {
      type: String,
      default: ''
    },
    percent: {
      type: Boolean,
      default: false
    },
    um: {
      type: Boolean,
      default: true
    },
    expandTable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      vBindOption: {
        itemKey: 'id',
        singleExpand: true,
        showExpand: this.expandTable
      }
    }
  },
  computed: {
    ...mapState('sale', ['isTableLoading']),
    ...mapGetters('auth', ['isManagerIn']),
    getTableColumns () {
      const columns = [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.variants.cant'),
          value: 'cantTransactions',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.report.cant_operations'),
          value: 'totalOperations',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.report.netPrice'),
          value: 'netPrice',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.report.discountsSale'),
          value: 'totalDiscount',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.tax.total_pay_tax'),
          value: 'totalTax',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.report.grossSale'),
          value: 'grossPrice',
          select_filter: true
        }
      ]
      if (this.isManagerIn) {
        columns.push(
          {
            text: this.$vuetify.lang.t('$vuetify.variants.total_cost'),
            value: 'totalCost',
            select_filter: true
          },
          {
            text: this.$vuetify.lang.t('$vuetify.dashboard.revenue') + '($)',
            value: 'margin',
            select_filter: true
          })
        if (this.percent) {
          columns.push(
            {
              text: this.$vuetify.lang.t('$vuetify.dashboard.revenue') + '%',
              value: 'margin_percent',
              select_filter: true
            })
        }
      }
      return columns
    }
  }
}
</script>

<style scoped>

</style>
