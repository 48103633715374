<template>
  <v-container>
    <v-list-item-group v-if="marks.length > 0"
                       color="primary"
    >
      <v-list-item v-for="(item, i) in marks.slice( 0, 5)"
                   :key="i">
        <v-list-item-icon>
          <v-chip :color="item.color" dark >
            {{ item.color }}
          </v-chip>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            v-text="
                                  `${item.name !== '' ? item.name : $vuetify.lang.t('$vuetify.no_defined')}`
                                "
          />
          <v-list-item-subtitle
            v-text=" `${parseFloat(item.grossPrice).toFixed(2) + ' ' + currency}`
                                "
          />
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
    <p v-else>No hay ventas por marcas registradas</p>
  </v-container>
</template>

<script>
export default {
  name: 'MarkSummary',
  props: {
    marks: {
      type: Array,
      default: function () {
        return []
      }
    },
    currency: {
      type: String,
      default: function () {
        return ''
      }
    }
  }
}
</script>

<style scoped>

</style>
